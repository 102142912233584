import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable, getFunctions } from "firebase/functions";
import { capitalizeFirstLetter } from "../utils/stringUtils";
import strings from "../utils/strings";

import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useBanner } from "../context/BannerContext";

import { navigateToSubscriptionPage } from "../utils/PageNav";

import {
  getGroupRequests,
  requestToJoinGroup,
  rejectRequest,
} from "../utils/FirestoreDB.js";

const GroupDetails = () => {
  const {
    user,
    userData,
    isOwner,
    isAdminOrOwner,
    refreshUserData,
    userSubgroup,
    hasPremiumSubscription,
    groupCounts,
  } = useContext(UserContext);
  const { showBanner } = useBanner();

  const navigate = useNavigate();
  const functions = getFunctions();

  const [groupInput, setGroupInput] = useState("");
  const [newGroupNameInput, setNewGroupNameInput] = useState("");
  const hasReachedUserLimit = groupCounts?.userCount >= groupCounts?.userMax;

  const handleCreateNewGroup = async () => {
    try {
      const groupName = newGroupNameInput.trim();
      if (!groupName) {
        showBanner("Please enter a valid Group Name.", "error");
        return;
      }
      const createGroupFunc = httpsCallable(functions, "createGroup");
      const result = await createGroupFunc({ groupName });
      refreshUserData();
      showBanner(
        `Group ${groupName} (${result.data.groupId}) has been created!`,
        "success"
      );
    } catch (error) {
      console.error("Error creating new group: ", error);
      showBanner("An error occurred while creating the new group.", "error");
    }
  };

  const handleLeaveGroup = async () => {
    if (window.confirm("Are you sure you want to leave the group?")) {
      try {
        const removeUserFunc = httpsCallable(functions, "removeUserFromGroup");
        const result = await removeUserFunc({
          groupId: userData.group_id,
          userId: user.uid,
        });
        console.log(result.data.message);
        showBanner("You have successfully left the group.", "success");
        window.location.href = window.location.href;
      } catch (error) {
        console.error("Error leaving the group: ", error);
        showBanner(
          "An error occurred while trying to leave the group.",
          "error"
        );
      }
    }
  };

  const handleJoinGroupRequest = async () => {
    try {
      const groupInputTrimmed = groupInput.trim();
      if (!groupInputTrimmed) {
        showBanner("Please enter a valid Group ID.", "error");
        return;
      }
      await requestToJoinGroup(groupInputTrimmed);
      showBanner(
        `Request to join group ${groupInputTrimmed} has been submitted.`,
        "success"
      );
    } catch (error) {
      console.error("Error requesting to join group: ", error);
      showBanner(
        "An error occurred while requesting to join the group.",
        "error"
      );
    }
  };

  const onManageSubscriptionClick = () => {
    navigateToSubscriptionPage(navigate);
  };

  const subscriptionDefinition = (plan) => {
    if (plan.toLowerCase().includes("basic")) {
      return strings.subTier1Desc;
    } else if (plan.toLowerCase().includes("standard")) {
      return strings.subTier2Desc;
    } else if (plan.toLowerCase().includes("premium")) {
      return strings.subTier3Desc;
    } else {
      return strings.subTier0Desc;
    }
  };

  const userRoleDefinition = (role) => {
    switch (role) {
      case "owner":
        return strings.userOwnerRole;
      case "admin":
        return strings.userAdminRole;
      case "editor":
        return strings.userEditorRole;
      default:
        return strings.userDefaultRole;
    }
  };

  return (
    <div className="company-section">
      <h2>Group Details</h2>

      {userData && userData.group_name && (
        <>
          <h3>Group Name</h3>
          <p>{userData.group_name}</p>
        </>
      )}

      {userData && userData.group_id ? (
        <>
          <h3>Group ID</h3>
          <p>{userData.group_id}</p>
          {!isOwner && <button onClick={handleLeaveGroup}>Leave Group</button>}
        </>
      ) : (
        <>
          <p>Create a new group or join an existing group.</p>
          <h4>Create New Group</h4>
          <input
            type="text"
            placeholder="Enter Group Name"
            value={newGroupNameInput}
            onChange={(e) => setNewGroupNameInput(e.target.value)}
            className="account-page-text-input"
          />
          <button onClick={handleCreateNewGroup}>Create New Group</button>
          <h4>Join Existing Group</h4>
          <div>
            <input
              type="text"
              placeholder="Enter Group ID"
              value={groupInput}
              onChange={(e) => setGroupInput(e.target.value)}
              className="account-page-text-input"
            />
            <button onClick={handleJoinGroupRequest}>
              Request to Join Group
            </button>
          </div>
        </>
      )}

      <h3>Subscription Plan</h3>
      {userData && userData.subscription_plan ? (
        <>
          <h4>{capitalizeFirstLetter(userData.subscription_plan)}</h4>
          <p>{subscriptionDefinition(userData.subscription_plan)}</p>
        </>
      ) : (
        <>
          <h4>{capitalizeFirstLetter("free")}</h4>
          <p>{subscriptionDefinition("free")}</p>
        </>
      )}
      {isAdminOrOwner && (
        <button onClick={onManageSubscriptionClick}>Manage Subscription</button>
      )}

      {hasPremiumSubscription && (
        <>
          <h3>User Role</h3>
          {userData && userData.role ? (
            <>
              <h4>{capitalizeFirstLetter(userData.role)}</h4>
              <p>{userRoleDefinition(userData.role)}</p>
            </>
          ) : (
            <p>No user role assigned. Contact your group admin.</p>
          )}

          {userSubgroup && (
            <>
              <h3>Subgroup</h3>
              <p>{userSubgroup.subgroupName}</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GroupDetails;
