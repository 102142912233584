import React, { createContext, useState, useEffect, useRef } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getGroupIdForUser,
  getUserRole,
  getGroupDetails,
} from "../utils/FirestoreDB";
import { doc, onSnapshot } from "firebase/firestore"; 
import { createUser } from "../utils/FirebaseAuth";
import strings from "../utils/strings";

// import { useFetchUserData } from "./hooks/useFetchUserData";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserDataState] = useState(null);

  const [loading, setLoading] = useState(true); // Loading state for async actions
  const [hasPremiumSubscription, setHasPremiumSubscription] = useState(false);

  const [isOwner, setIsOwner] = useState(false);
  const [isAdminOrOwner, setIsAdminOrOwnerState] = useState(false);

  const [isScaleMaxed, setIsScaleMaxed] = useState(false);

  const [parts, setParts] = useState([]);
  const [scales, setScales] = useState(null);

  const [groupCounts, setGroupCounts] = useState(null);

  const [subgroups, setSubgroups] = useState([]);
  const [userSubgroup, setUserSubgroupState] = useState(null);

  const userDataRef = useRef(userData);
  const userSubgroupRef = useRef(userSubgroup);
  const isAdminOrOwnerRef = useRef(isAdminOrOwner);

  const setUserData = (newData) => {
    setUserDataState(newData);
    userDataRef.current = newData;
  };

  const setUserSubgroup = (newData) => {
    setUserSubgroupState(newData);
    userSubgroupRef.current = newData;
  };

  const setIsAdminOrOwner = (newState) => {
    setIsAdminOrOwnerState(newState);
    isAdminOrOwnerRef.current = newState;
  };

  // Function to fetch parts by subgroup
  const fetchPartsBySubgroup = async (group_id=null) => {
    const functions = getFunctions();
    const fetchParts = httpsCallable(functions, "fetchPartsBySubgroup");

    try {
      // If user is an admin or owner, don't pass subgroupId
      const groupId = userDataRef.current?.group_id || group_id;
      const subgroupId = isAdminOrOwnerRef.current ? null : userSubgroupRef.current.subgroupId;

      // console.log(`${groupId}, ${userSubgroup}/${subgroupId}, ${isAdminOrOwner}`);

      const response = await fetchParts({
        groupId,
        subgroupId,
      });
      console.log(response.data);
      if (response.data.success) {
        setParts(response.data.parts); // Store parts globally in context state
      } else {
        console.error("Failed to fetch parts:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching parts by subgroup:", error);
    }
  };

  // Fetch group details from Firestore based on group_id
  const fetchGroupDetails = async (group_id) => {
    try {
      const groupDetails = await getGroupDetails(group_id);
      return groupDetails;
    } catch (error) {
      console.error("Error fetching group details:", error);
      return null;
    }
  };

  const updateGroupDetails = async (groupId) => {
    const groupDetails = await fetchGroupDetails(groupId);
    console.log(groupDetails);

    const groupCounts = {
      partCount: groupDetails.partCount,
      partMax: groupDetails.partCount,
      userCount: groupDetails.userCount,
      userMax: groupDetails.userMax,
      scaleCount: groupDetails.scaleCount,
      scaleMax: groupDetails.scaleMax,
    };

    const hasReachedScaleLimit =
      groupCounts?.scaleCount >= groupCounts?.scaleMax;

    setIsScaleMaxed(hasReachedScaleLimit);
    setGroupCounts(groupCounts);

    return groupDetails;
  };

  // scale count listener
  useEffect(() => {
    if (userData?.group_id) {
      // Reference the group document
      const groupDocRef = doc(db, "user_groups", userData.group_id);

      // Listen for changes to the scaleCount field in real-time
      const unsubscribe = onSnapshot(groupDocRef, (doc) => {
        if (doc.exists()) {
          const updatedData = doc.data();
          const updatedScaleCount = updatedData.scaleCount;

          // If the scale count changes, update the group scales
          if (updatedScaleCount !== groupCounts?.scaleCount) {
            updateGroupScales(userData.group_id);
            setGroupCounts(prevCounts => ({
              ...prevCounts,
              scaleCount: updatedScaleCount,
            }));
          }
        }
      });

      // Cleanup the listener when component unmounts
      return () => unsubscribe();
    }
  }, [userData?.group_id, groupCounts?.scaleCount]);

  const currentSubscription = (subscriptionStatus) => {
    if (
      subscriptionStatus &&
      (subscriptionStatus.status === "active" ||
        subscriptionStatus.status === "trialing")
    ) {
      // console.log(subscriptionStatus);

      if (subscriptionStatus.planName.toLowerCase().includes("basic")) {
        return strings.subTier1;
      } else if (
        subscriptionStatus.planName.toLowerCase().includes("standard")
      ) {
        return strings.subTier2;
      } else if (
        subscriptionStatus.planName.toLowerCase().includes("premium")
      ) {
        return strings.subTier3;
      } else {
        return strings.subTier0;
      }
    } else {
      return strings.subTier0;
    }
  };

  // Fetch the registered scales for the group
  const fetchGroupScales = async (groupId) => {
    try {
      const functions = getFunctions();
      const getScalesFunc = httpsCallable(functions, "getGroupScales");
      const result = await getScalesFunc({ groupId });
      return result.data.scales;
    } catch (error) {
      console.error("Error fetching group scales: ", error);
    }
  };

  const updateGroupScales = async (groupId) => {
    const groupScales = await fetchGroupScales(groupId);
    console.log(groupScales);
    setScales(groupScales);
  };

  const fetchSubgroups = async (groupId) => {
    const functions = getFunctions();
    try {
      const fetchSubgroupsFunc = httpsCallable(functions, "fetchSubgroups");

      // Fetch the response from the callable function
      const response = await fetchSubgroupsFunc({ groupId });
      const { success, subgroups } = response.data; // Make sure to access `response.data`

      //   console.log(success);
      //   console.log(subgroups);

      if (success) {
        setSubgroups(subgroups);
        return subgroups;
      }
    } catch (error) {
      console.error("Error fetching subgroups: ", error);
    }
  };

  const updateSubgroups = async (groupId) => {
    console.log(groupId);
    const subgroups = await fetchSubgroups(groupId);
    console.log(subgroups);
    setSubgroups(subgroups);
  };

  const fetchUserSubgroup = async (groupId, userId) => {
    const functions = getFunctions();
    const getUserSubgroup = httpsCallable(functions, "getUserSubgroup");

    try {
      const result = await getUserSubgroup({ groupId, userId });
      if (result.data.success) {
        console.log("Subgroup ID:", result.data.subgroupId);
        console.log("Subgroup Name:", result.data.subgroupName);
        const userSubgroupData = {
          subgroupId: result.data.subgroupId,
          subgroupName: result.data.subgroupName,
        };
        return userSubgroupData;
      } else {
        console.error("Failed to get subgroup information");
      }
    } catch (error) {
      console.error("Error fetching user's subgroup:", error);
    }
  };

  //technically all data, not just user data
  const fetchUserData = async (currentUser) => {
    if (currentUser) {
      try {
        // Promise #1: getUserRole and getGroupIdForUser
        const [role, group_id] = await Promise.all([
          getUserRole(currentUser.uid),
          getGroupIdForUser(currentUser.uid),
        ]);

        // If the role is null, create the user
        if (role === null) {
          await createUser(currentUser);
        }

        let groupDetails = null;
        let subscriptionResponse = null;
        let userSubgroupData = null;

        if (group_id) {
          // Promise #2: updateGroupDetails, updateGroupScales, fetchSubscriptionStatus, updateSubgroups
          [groupDetails, , subscriptionResponse, , userSubgroupData] =
            await Promise.all([
              updateGroupDetails(group_id),
              updateGroupScales(group_id),
              fetchSubscriptionStatus(),
              updateSubgroups(group_id),
              fetchUserSubgroup(group_id, currentUser.uid),
            ]);

          const isOwnerBool = role === "owner";
          const isAdminOrOwnerBool = role === "admin" || role === "owner";

          console.log(userSubgroupData);
          setUserSubgroup(userSubgroupData);
          setIsOwner(isOwnerBool);
          setIsAdminOrOwner(isAdminOrOwnerBool);

          fetchPartsBySubgroup(group_id);
        } else {
          console.log("groupless user");
          setScales([]);
        }

        const subscriptionStatus = subscriptionResponse
          ? subscriptionResponse
          : { status: "no-subscription" };

        const userDataFromFirestore = {
          group_id,
          email: currentUser.email,
          role,
          displayName: currentUser.displayName || null,
          profilePicture: currentUser.photoURL || null,
          group_name: groupDetails?.group_name || null,
          subscription_plan: currentSubscription(subscriptionStatus),
          stripeCustomerId: groupDetails?.stripeCustomerId || null,
          subscriptionStatus,
        };

        console.log(userDataFromFirestore);

        const hasPremiumSubscription =
          userDataFromFirestore?.subscription_plan &&
          userDataFromFirestore?.subscription_plan !== "free";

        setHasPremiumSubscription(hasPremiumSubscription);

        setUserData(userDataFromFirestore);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      setUserData(null);
    }
  };

  // Function to fetch subscription status from Firebase Functions
  const fetchSubscriptionStatus = async () => {
    // console.log("fetchSubscriptionStatus: ", user);
    // if (!user) return;

    try {
      const functions = getFunctions();
      const getSubscriptionStatus = httpsCallable(
        functions,
        "getSubscriptionStatus"
      );
      const { data } = await getSubscriptionStatus();
      console.log("fetchSubscriptionStatus: ", data);
      return data;
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };

  // Function to refresh the user data manually
  const refreshUserData = async () => {
    if (user) {
      await fetchUserData(user); // Fetch the latest user data
    }
  };


  // Firebase Auth state change listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(true); // Start loading while user data is fetched
      if (currentUser) {
        await fetchUserData(currentUser);
      } else {
        setUserData(null); // No user, clear the userData
      }
      setLoading(false); // Data fetching complete
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        userData,
        parts,
        fetchPartsBySubgroup,
        groupCounts,
        loading,
        refreshUserData,
        hasPremiumSubscription,
        currentSubscription,
        scales,
        isOwner,
        isAdminOrOwner,
        fetchGroupScales,
        updateGroupScales,
        updateGroupDetails,
        isScaleMaxed,
        subgroups,
        updateSubgroups,
        userSubgroup,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
