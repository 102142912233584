import React, { useState, useEffect, useContext } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { DataGrid } from "@mui/x-data-grid";
import "../styles/GroupScalesList.css";

import LoadingContentWrapper from "../components/LoadingContentWrapper";

import { UserContext } from "../context/UserContext.js";
import { useSerial } from "../context/SerialContext.js";
import { useModal } from "../context/ModalContext";

function GroupScalesList({ isLoading, setIsLoading }) {
  const {
    userData,
    scales,
    isAdminOrOwner,
    groupCounts,
    updateGroupScales,
    hasPremiumSubscription,
    updateGroupDetails,
    isScaleMaxed,
  } = useContext(UserContext);

  const { isConnected, onConnectBtn, scaleId } = useSerial();

  const functions = getFunctions();
  const { showMessageModal, showActionModal, closeModal } = useModal(); // Use the modal hook

  const [loadingScales, setLoadingScales] = useState(false);

  const groupId = userData?.group_id;

  const handleAddScale = async () => {
    setLoadingScales(true);
    try {
      if (!(isConnected && scaleId)) {
        await onConnectBtn();
      }

      if (scaleId === null) return;

      showActionModal(
        "Register Scale",
        `Do you want to register this scale (ID: ${scaleId})?`,
        async () => {
          closeModal();
          setIsLoading(true);
          try {
            const addScaleFunc = httpsCallable(functions, "addScaleToGroup");
            const result = await addScaleFunc({ groupId, scaleId: scaleId });
            console.log(result.data.message);
            if (!result.data.success) {
              showMessageModal(
                "Error",
                `Unable to register scale. ${result.data.message}`
              );
            }
          } catch (error) {
            showMessageModal("Error", `Error adding scale: ${error.message}`);
          } finally {
            setIsLoading(false);
            window.location.reload();
          }
        },
        closeModal
      );
    } catch (error) {
      console.error("Error adding scale: ", error);
      showMessageModal("Error", `Error adding scale: ${error.message}`);
    } finally {
      setLoadingScales(false);
      updateGroupDetails(groupId);
    }
  };

  // Handle removing a scale
  const handleRemoveScale = async (scaleId) => {
    showActionModal(
      "Remove Scale",
      "Are you sure you want to remove this scale?",
      async () => {
        setLoadingScales(true);
        try {
          const removeScaleFunc = httpsCallable(
            functions,
            "removeScaleFromGroup"
          );
          await removeScaleFunc({ groupId, scaleId });
          updateGroupScales(groupId); // Refresh the scale list after removal
        } catch (error) {
          showMessageModal("Error", `Error removing scale: ${error.message}`);
        } finally {
          setLoadingScales(false);
          closeModal();
          window.location.reload();
          // updateGroupDetails(groupId);
        }
      },
      closeModal
    );
  };

  if (loadingScales) {
    return (
      <LoadingContentWrapper
        loading={loadingScales}
        accessibilityLabel="Loading scales..."
      ></LoadingContentWrapper>
    );
  }

  if (!hasPremiumSubscription) {
    return null;
  }

  const columns = [
    { field: "id", headerName: "Scale ID", flex: 1 },
    isAdminOrOwner && {
      field: "remove",
      headerName: "Remove",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <button
          className="remove-btn"
          title="Remove"
          onClick={() => handleRemoveScale(params.row.id)}
        >
          ❌
        </button>
      ),
    },
  ].filter(Boolean); // Filter out `null` columns if isAdminOrOwner is false

  return (
    <div className="group-scales-section">
      <h3>Registered Scales</h3>
      {isScaleMaxed ? (
        <p>You have reached the maximum number of scales for your plan.</p>
      ) : (
        <div className="add-scale-section">
          <button onClick={handleAddScale}>Add Scale</button>
        </div>
      )}
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          autoHeight
          rows={scales.map((scale) => ({ id: scale.id }))} // Ensure DataGrid has an `id` field
          columns={columns}
          pageSize={5}
          disableSelectionOnClick
          localeText={{
            noRowsLabel:
              "No scales registered. Please register a scale to start using it.",
          }}
          disableColumnReorder
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              fontWeight: "bold",
              fontSize: "16px",
              borderBottom: "2px solid #ccc",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold", // Targets the header text directly to make it bold
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid #ddd",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#f9f9f9",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none !important", // Hide the column resize handles
            },
          }}
        />
      </div>
    </div>
  );
}

export default GroupScalesList;
