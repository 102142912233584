import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getGroupRequests,
  requestToJoinGroup,
  rejectRequest,
} from "../utils/FirestoreDB.js";

import GroupUsersList from "../components/GroupUsersList";
import GroupUserInvites from "../components/GroupUserInvites";
import GroupJoinRequests from "../components/GroupJoinRequests";
import LoadingSpinner from "../components/LoadingSpinner.js";
import LoadingSpinnerOverlay from "../components/LoadingSpinnerOverlay.js";
import GroupScalesList from "../components/GroupScalesList.js";
import SignoutButton from "../components/SignoutButton.js";
import GroupDetails from "../components/GroupDetails"; // Import the new component

import { Divider } from "gestalt";

import { UserContext } from "../context/UserContext";

import "./AccountPage.css"; // Styles for AccountPage
import { getFunctions, httpsCallable } from "firebase/functions";


function AccountPage() {
  const {
    user,
    userData,
    hasPremiumSubscription,
    loading,
    refreshUserData,
    groupCounts,
    isOwner,
    isAdminOrOwner,
    userSubgroup,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const functions = getFunctions();

  const [requests, setRequests] = useState([]);
  const [groupInput, setGroupInput] = useState("");
  const [newGroupNameInput, setNewGroupNameInput] = useState("");
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hasReachedUserLimit = groupCounts?.userCount >= groupCounts?.userMax;

  useEffect(() => {
    if (!loading && !userData) {
      refreshUserData(); // Fetch fresh user data if missing
    }
  }, [loading, userData, refreshUserData]);

  useEffect(() => {
    if (userData && userData.group_id && isAdminOrOwner) {
      fetchGroupRequests(userData.group_id);
    }
  }, [isAdminOrOwner, userData]);

  const fetchGroupRequests = async (groupId) => {
    setLoadingRequests(true);
    try {
      if (!groupId || !isAdminOrOwner) return;
      const requests = await getGroupRequests(groupId);
      setRequests(requests);
    } catch (error) {
      console.error("Error fetching group requests: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  const handleApproveRequest = async (groupId, requestId) => {
    setLoadingRequests(true);
    try {
      const approveRequestFunc = httpsCallable(functions, "approveRequest");
      const result = await approveRequestFunc({ groupId, requestId });
      console.log(result.data.message);
      fetchGroupRequests(groupId);
    } catch (error) {
      console.error("Error approving request: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  const handleRejectRequest = async (groupId, requestId) => {
    setLoadingRequests(true);
    try {
      await rejectRequest(groupId, requestId);
      fetchGroupRequests(groupId);
    } catch (error) {
      console.error("Error rejecting request: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  return (
    <div id="account-page">
      <div className="account-page-header-container">
        <h3 id="account-page-title">Account</h3>
      </div>
      <div className="account-container">
        <div className="account-page-section">
          {user && (
            <div>
              {/* Keep the Welcome Message and Email */}
              <h2>Welcome, {user.displayName}!</h2>
              <p>Email: {user.email}</p>
            </div>
          )}
          <SignoutButton user={user} />
          {user && (
            <>
              <GroupDetails/>

              {isAdminOrOwner && hasPremiumSubscription && (
                <>
                  {!hasReachedUserLimit && (
                    <>
                      <GroupUserInvites />
                      <GroupJoinRequests
                        requests={requests}
                        loading={loadingRequests}
                        handleApproveRequest={handleApproveRequest}
                        handleRejectRequest={handleRejectRequest}
                      />
                    </>
                  )}

                  {/* Use GroupUsersList component */}
                  <GroupUsersList />
                </>
              )}
              <GroupScalesList
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </>
          )}
        </div>
      </div>
      {isLoading && <LoadingSpinnerOverlay />}
    </div>
  );
}

export default AccountPage;
