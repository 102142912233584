import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CancelPage.css"; // Create this CSS file for HelpPage specific styles
import { navigateToHomePage } from "../utils/PageNav";

function CancelPage() {
  const navigate = useNavigate();

  const onBackClick = () => {
    navigateToHomePage(navigate);
  };

  return (
    <div id="cancel-page">
      <div id="cancelSection">
        <div className="container">
          <div>
            <h4>Transaction Canceled</h4>

            <p>
              Your transaction has been canceled. No charges have been made.
            </p>
          </div>
          <div className="cancel-button-group">
            <button id="backBtn" onClick={onBackClick}>
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelPage;
