import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";

import "../../styles/ItemSelectSection.css";

import { convertWeight, getDefaultUnits } from "../../utils/units";
import { navigateToPartsPage } from "../../utils/PageNav";
import { setSelectedSku } from "../../redux/slices/skuSlice";

import { UserContext } from "../../context/UserContext";
import { useSerial } from "../../context/SerialContext.js";

function ItemSelectSection({ setIsPrintEnabled }) {
  const {
    userData,
    hasPremiumSubscription,
    parts,
  } = useContext(UserContext);
  const isBasicUser = userData?.role === "user";

  const { pieceWeight, setPieceWeight } = useSerial();

  let pieceWeightLb = 1;
  let pieceWeightKg = 1;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedSkuData = useSelector((state) => state.sku);
  // let skuParts = useSelector((state) => state.skuParts.parts);

  // Function to populate the SKU dropdown
  async function populateSKU() {
    if (!hasPremiumSubscription) return;

    // console.log("populateSKU");
    const skuDropdown = document.getElementById("sku");

    if (!skuDropdown) return; // Ensure skuDropdown exists

    skuDropdown.innerHTML = "";
    // console.log(skuDropdown);

    const defaultOption = document.createElement("option");
    defaultOption.value = "";
    defaultOption.disabled = true;
    defaultOption.selected = true;
    defaultOption.textContent = "Select Item";
    skuDropdown.appendChild(defaultOption);

    // console.log(skuParts);
    // console.log(parts);
    parts.forEach((part) => {
      const option = document.createElement("option");
      option.value = part.part_no;
      option.textContent = part.part_no;
      option.setAttribute("part-name", part.name);
      option.setAttribute("piece-weight", part.piece_weight);
      option.setAttribute("units", part.units);
      skuDropdown.appendChild(option);
    });

    updateSelectedSkuInfo();

    // Attach event listener after populating and initializing Select2
    $(skuDropdown).on("change", onSkuSelect);
    // console.log(skuDropdown);
  }

  // Function to update the description and piece weight when an item is selected
  function updateDetails() {
    // console.log("updateDetails");
    try {
      const skuDropdown = document.getElementById("sku");
      const selectedPart = skuDropdown.options[skuDropdown.selectedIndex];

      // console.log(selectedPart);
      if (!selectedPart.value) {
        return;
      }

      const description = selectedPart.getAttribute("part-name");
      const units = selectedPart.getAttribute("units");

      document.getElementById("description").textContent = description;

      if (!isNaN(pieceWeight)) {
        const selectedUnit = $("#units").val();

        pieceWeightLb = pieceWeight;
        pieceWeightKg = convertWeight(pieceWeight, "lb", "kg");
        document.getElementById("pieceWeight").textContent = Number(
          convertWeight(pieceWeight, units, selectedUnit)
        ).toFixed(4);

        saveSkuSelection();

      }
    } catch (error) {
      console.log("Error updating details", error.message);
    }
  }

  function saveSkuSelection() {
    // console.log("saveSkuSelection");
    try {
      const skuDropdown = document.getElementById("sku");
      const selectedPart = skuDropdown.options[skuDropdown.selectedIndex];

      const sku = selectedPart.value;
      const description = selectedPart.getAttribute("part-name");
      setPieceWeight(selectedPart.getAttribute("piece-weight"));
      const units = selectedPart.getAttribute("units");

      dispatch(
        setSelectedSku({
          selectedSku: sku,
          description,
          pieceWeight,
          units,
        })
      );
    } catch (error) {
      console.log("Error saving SKU selection", error.message);
    }
  }

  function onSkuSelect() {
    // console.log("onSkuSelect");
    // saveSkuSelection();
    updateDetails();
    setIsPrintEnabled(true);
  }

  function updateSelectedSkuInfo() {
    console.log("updateSelectedSkuInfo");
    const skuDropdown = document.getElementById("sku");

    // console.log(skuDropdown);
    console.log(selectedSkuData);

    if (skuDropdown && selectedSkuData.selectedSku) {
      // Check if the selectedSku exists in the parts array
      const selectedPart = parts.find(
        (part) => part.part_no === selectedSkuData.selectedSku
      );

      if (selectedPart) {
        // If the selectedSku exists in parts, update the dropdown and UI
        skuDropdown.value = selectedPart.part_no;
        $(skuDropdown).trigger("change");

        // Assign description and piece weight based on the part values
        document.getElementById("description").textContent =
          selectedPart.name || "n/a";

        const selectedUnit = $("#units").val();
        // const pieceWeight = selectedPart.piece_weight || 0;
        // console.log("[setPieceWeight] ", selectedPart.piece_weight);
        setPieceWeight(selectedPart.piece_weight || 0);

        document.getElementById("pieceWeight").textContent = Number(
          convertWeight(pieceWeight, selectedPart.units, selectedUnit)
        ).toFixed(4);
      } else {
        // If the selectedSku does not exist in parts, handle this scenario
        console.warn(
          `Selected SKU (${selectedSkuData.selectedSku}) not found in parts.`
        );
        // Optionally, reset the dropdown and other fields
        skuDropdown.value = "";
        document.getElementById("description").textContent = "n/a";
        document.getElementById("pieceWeight").textContent = "n/a";
      }
    }
  }

  useEffect(() => {
    populateSKU();

    // Cleanup on unmount
    return () => {
      const skuDropdown = document.getElementById("sku");
      if (skuDropdown && $(skuDropdown).data("select2")) {
        $(skuDropdown).select2("destroy"); // Clean up Select2 to prevent memory leaks
      }
    };
  }, [parts]);

  const handleEditClick = () => {
    navigateToPartsPage(navigate);
  };

  return (
    <div className="item-select-container homepage-section">
      <div className="item-top-row">
        <div className="item-select">
          <label htmlFor="sku" className="label-block">
            Item ID
          </label>
          <select
            id="sku"
            name="sku"
            defaultValue=""
            className="readout"
            disabled={!hasPremiumSubscription}
          >
            <option disabled>Select Item</option>
          </select>
        </div>
        <div>
          <label htmlFor="description" className="label-block">
            Description
          </label>
          <span
            id="description"
            name="description"
            maxLength="32"
            className="item-select-description"
          >
            n/a
          </span>
        </div>
        <div id="pieceWeightContainer">
          <label htmlFor="pieceWeight" className="label-block">
            Piece Weight
          </label>
          <span id="pieceWeight" className="item-select-piece-weight">
            {pieceWeight ? pieceWeight: "n/a" }
          </span>
        </div>
      </div>
      {!isBasicUser && hasPremiumSubscription && (
        <button id="editButton" onClick={() => handleEditClick()}>
          ADD/EDIT
        </button>
      )}
    </div>
  );
}

export default ItemSelectSection;
