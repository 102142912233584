import { createSlice } from '@reduxjs/toolkit';

const skuSlice = createSlice({
  name: 'sku',
  initialState: {
    selectedSku: '',
    description: '',
    pieceWeight: 0,
    units: 'lb', // You can add any other fields you need
  },
  reducers: {
    setSelectedSku(state, action) {
      state.selectedSku = action.payload.selectedSku;
      state.description = action.payload.description;
      state.pieceWeight = action.payload.pieceWeight;
      state.units = action.payload.units;
    },
    clearSelectedSku(state) {
      state.selectedSku = '';
      state.description = '';
      state.pieceWeight = 0;
      state.units = 'lb';
    },
  },
});

export const { setSelectedSku, clearSelectedSku } = skuSlice.actions;
export default skuSlice.reducer;
