import { configureStore } from '@reduxjs/toolkit';
import skuReducer from './slices/skuSlice';
import skuPartsReducer from './slices/skuPartsSlice';
import deviceInfoReducer from './slices/deviceInfoSlice';

export const store = configureStore({
  reducer: {
    sku: skuReducer,
    skuParts: skuPartsReducer,
    deviceInfo: deviceInfoReducer,
  },
});
