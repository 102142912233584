import { createSlice } from '@reduxjs/toolkit';

const skuPartsSlice = createSlice({
  name: 'skuParts',
  initialState: {
    parts: [],
  },
  reducers: {
    setSkuParts(state, action) {
      state.parts = action.payload;
    },
  },
});

export const { setSkuParts } = skuPartsSlice.actions;
export default skuPartsSlice.reducer;
