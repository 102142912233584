import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "../../styles/Controls.css";
import "../../styles/ToggleReadButton.css";

import { downloadTextAsFile } from "../../utils/ImportExportFile.js";

import { useSerial } from "../../context/SerialContext.js";

function ControlsSection({ isPrintEnabled }) {
  const {
    isConnected,
    isScaleRegistered,
    tare,
    isReading,
    toggleReadHome,
  } = useSerial();

  const onPrint = async () => {
    console.log("print button clicked");
    const skuDropdown = document.getElementById("sku");
    const selectedPart = skuDropdown.options[skuDropdown.selectedIndex];

    if (!selectedPart.value) {
      return;
    }

    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();
    const partNo = selectedPart.value;
    const description = selectedPart.getAttribute("part-name");
    const counts = document.getElementById("itemCount").textContent;
    const weight = document.getElementById("totalWeight").textContent;
    const pieceWeight = selectedPart.getAttribute("piece-weight");
    const units = selectedPart.getAttribute("units");

    // printText indentation matters here, so be careful
    let printText = `nCounter Counting Solution
©2024 Loadstar Sensors
All rights reserved
--------------------------
Date: ${date}
Time: ${time}
Item ID: ${partNo}
Description: ${description}
Weight: ${weight} ${weight === "n/a" ? "" : units}
Piece Weight: ${pieceWeight} ${units}
Counts: ${counts}
--------------------------`;
    downloadTextAsFile(`${partNo}`, printText);
  };

  // console.log("isConnected: ", isConnected);
  // console.log("isRegistered: ", isScaleRegistered);
  // console.log(
  //   "!isConnected && !isScaleRegistered: ",
  //   !isConnected || !isScaleRegistered
  // );

  return (
    <div className="controls-container homepage-section">
      <div className="bottom-button-group homepage-bottom-buttons">
        <button
          id="tareButton"
          onClick={tare}
          disabled={!isConnected || !isScaleRegistered}
        >
          TARE
        </button>
        <button
          id="toggleReadButton"
          onClick={toggleReadHome}
          disabled={!isConnected || !isScaleRegistered}
        >
          <i className={isReading ? "fas fa-stop" : "fas fa-play"}></i>
          {"  "}
          {isReading ? "STOP" : "START"}
        </button>
        <button id="printButton" onClick={onPrint} disabled={!isPrintEnabled}>
          PRINT
        </button>
      </div>
    </div>
  );
}

export default ControlsSection;
