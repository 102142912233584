import React, { useState, useEffect } from "react";
import "../../styles/Readout.css";
import "../../styles/Common.css";

import { convertWeight } from "../../utils/units";

import { useUserContext } from "../../hooks/userUserContext.js";
import { useSerial } from "../../context/SerialContext.js";

function Readout() {
  const {
    weight,
    scaleId,
    scaleCapacity,
    scaleUnits,
    avgPoints,
    avgPointSetting,
    setAvgPointSetting,
    pieceWeight,
    overrideWeight,
    setOverrideWeight,
  } = useSerial();

  const [overrideDisabled, setOverrideDisabled] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState("lb");

  useEffect(() => {

    if (overrideWeight && !overrideDisabled) {
      avgPoints.updateX(parseInt(overrideWeight, 10));
    }
  }, [overrideWeight, overrideDisabled]);

  useEffect(()=>{

  },[pieceWeight]);

  // Handler for when the user changes the unit
  const handleUnitsChange = (event) => {
    const selectedValue = event.target.value;
    // console.log(selectedValue);
    setSelectedUnit(selectedValue);
    updatePieceWeightVal(selectedValue);
  };

  const updatePieceWeightVal = () => {
    // console.log("updateDetails");
    const skuDropdown = document.getElementById("sku");
    const selectedPart = skuDropdown.options[skuDropdown.selectedIndex];

    if (!selectedPart.value) {
      return;
    }

    const description = selectedPart.getAttribute("part-name");
    const pieceWeight = selectedPart.getAttribute("piece-weight");
    const units = selectedPart.getAttribute("units");

    document.getElementById("description").textContent = description;

    // let pieceWeight = part.piece_weight;
    // console.log(pieceWeight);
    if (!isNaN(pieceWeight)) {
      // console.log("pieceWeight is a number");
      const selectedUnit = document.getElementById("units").value;
      document.getElementById("pieceWeight").textContent = Number(
        convertWeight(pieceWeight, units, selectedUnit)
      ).toFixed(4);
    }
  };

  const handleOverrideTextChange = (event) => {
    // console.log("handleInputChange");
    const inputValue = event.target.value;

    // Regular expression to match valid numbers (including decimals)
    const validNumberRegex = /^-?\d*\.?\d*$/;

    if (validNumberRegex.test(inputValue)) {
      const val = parseFloat(inputValue);
      console.log(val);
      setOverrideWeight(inputValue); // Keep the input as a string to allow partial inputs like "1."
    } else {
      console.log("Invalid input: not a number");
    }
  };

  const handleOverrideToggle = () => {
    setOverrideDisabled((prevState) => {
      const newState = !prevState;

      const pieceWeightOverride = document.getElementById(
        "pieceWeightOverride"
      );
      if (newState) {
        // Disabled
        pieceWeightOverride.value = "1"; // Reset value or handle as necessary
        // console.log("override disabled");
      } else {
        // Enabled
        // console.log("override enabled");
      }

      return newState; // Return the updated state
    });
  };

  const handleAveragePointsChange = (event) => {
    const selectedValue = event.target.value;
    console.log("handleAveragePointsChange ", selectedValue);
    setAvgPointSetting(selectedValue); // Update the state
    avgPoints.updateX(parseInt(selectedValue, 10));
  };

  return (
    <div className="readout-container homepage-section">
      <div className="above-readout-container">
        <label htmlFor="itemCount" className="label-inline">
          Counts
        </label>
        <div className="sensor-val-container">
          <div id="device-id-div">
            {`Sensor ID: ${scaleId ? scaleId : "n/a"} | `}&nbsp;
          </div>
          <div id="device-weight-cap-div">
            {`Capacity: ${scaleCapacity ? scaleCapacity : "n/a"} | `}&nbsp;
          </div>
          <div id="device-default-units-div">
            {`Default: ${scaleUnits ? scaleUnits : "n/a"}`}
          </div>
        </div>
      </div>

      <div className="count-container">
        <div id="itemCount">n/a</div>
      </div>
      <div>
        <div className="bottom-readout-container">
          <div id="totalWeightContainer">
            <label htmlFor="totalWeight" className="label-block">
              Weight
            </label>
            <span id="totalWeight" className="bottom-readout">
              {weight ? weight.toFixed(4) : "n/a"}
            </span>
          </div>
          <div id="unitsContainer">
            <label htmlFor="units" className="label-block">
              Units
            </label>
            <select
              id="units"
              name="units"
              className="bottom-readout"
              value={selectedUnit} // Controlled by state
              onChange={handleUnitsChange}
            >
              <option value="lb">lb</option>
              <option value="kg">kg</option>
            </select>
          </div>

          <div id="averagePointsContainer">
            <label htmlFor="averagePoints" className="label-block">
              Pts to Average
            </label>
            <select
              id="averagePoints"
              name="averagePoints"
              className="bottom-readout"
              value={avgPointSetting}
              onChange={handleAveragePointsChange}
            >
              <option value="1">1</option>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div id="pieceWeightOverrideContainer">
            <div className="pwoLabelDiv">
              <label htmlFor="pieceWeightOverride" className="label-block">
                Pc Wt Override
              </label>
              <label className="switch">
                <input
                  type="checkbox"
                  id="overrideToggle"
                  onChange={handleOverrideToggle}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <input
              type="text"
              id="pieceWeightOverride"
              name="pieceWeightOverride"
              value={overrideWeight || 1}
              disabled={overrideDisabled}
              className="bottom-readout"
              onChange={handleOverrideTextChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Readout;
