import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getGroupUsers } from "../utils/FirestoreDB.js";
import { capitalizeFirstLetter } from "../utils/stringUtils.js";
import LoadingContentWrapper from "../components/LoadingContentWrapper";
import { UserContext } from "../context/UserContext.js";
import { Select, MenuItem } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

function SubgroupUserList() {
  const { userData, subgroups, updateSubgroups } = useContext(UserContext);
  const [groupUsers, setGroupUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingAssignment, setLoadingAssignment] = useState({}); // Loading state for each user

  const groupId = userData?.group_id;

  const functions = getFunctions();

  useEffect(() => {
    if (groupId) {
      fetchGroupUsers();
    }
  }, [groupId]);

  const fetchGroupUsers = async () => {
    setLoadingUsers(true);
    try {
      const users = await getGroupUsers(groupId);
      setGroupUsers(users);
    } catch (error) {
      console.error("Error fetching group users: ", error);
    } finally {
      setLoadingUsers(false);
    }
  };
  // Function to handle subgroup assignment
  const handleSubgroupChange = async (userId, subgroupId) => {
    setLoadingAssignment((prev) => ({ ...prev, [userId]: true })); // Set loading state for specific user
    try {
      console.log(`${groupId}, ${userId}, ${subgroupId}`);
      const assignUserToSubgroup = httpsCallable(
        functions,
        "assignUserToSubgroup"
      );
      const result = await assignUserToSubgroup({
        groupId,
        userId,
        subgroupId,
      });
      if (result.data.success) {
        console.log(`User ${userId} assigned to subgroup ${subgroupId}`);
        fetchGroupUsers(); // Refresh user list after assignment
      }
    } catch (error) {
      console.error("Error assigning user to subgroup:", error);
    } finally {
      setLoadingAssignment((prev) => ({ ...prev, [userId]: false })); // Reset loading state after assignment
    }
  };

  const userColumns = [
    {
      field: "displayName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => params.row.displayName || "Unknown",
    },
    { field: "email", headerName: "Email", flex: 1.5 },
    {
      field: "subgroup",
      headerName: "Subgroup",
      flex: 1,
      renderCell: (params) => {
        const subgroupUser = params.row;
        const isLoading = loadingAssignment[subgroupUser.id] || false; // Check loading state for user

        return subgroupUser.role === "owner" ||
          subgroupUser.role === "admin" ? (
          <span>{capitalizeFirstLetter(subgroupUser.role)}</span>
        ) : isLoading ? ( // Show loading wrapper if subgroup assignment is in progress
          <LoadingContentWrapper
            loading={true}
            accessibilityLabel="Assigning subgroup..."
          />
        ) : (
          <Select
            value={subgroupUser.subgroupId || ""} // Ensure the subgroup field is populated
            onChange={(e) =>
              handleSubgroupChange(subgroupUser.id, e.target.value)
            }
            sx={{
              width: "150px", // Consistent width
              height: "35px", // Set height of the select box
              fontSize: "0.875rem", // Match DataGrid font size
              fontWeight: "400", // Match DataGrid font weight
              color: "#333", // Match DataGrid font color
              "& .MuiSelect-select": {
                padding: "10px", // Add padding inside the select box
              },
            }}
          >
            <MenuItem value="" disabled>
              Select Subgroup
            </MenuItem>
            {subgroups.map((subgroup) => (
              <MenuItem key={subgroup.id} value={subgroup.id}>
                {subgroup.name}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];

  if (loadingUsers) {
    return (
      <>
        <h3>Users</h3>
        <LoadingContentWrapper
          loading={loadingUsers}
          accessibilityLabel="Loading users..."
        />
      </>
    );
  }

  return (
    <div>
      <h3>Subgroup Assignment</h3>
      {groupUsers.length === 0 ? (
        <p>No users available</p>
      ) : (
        <>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              autoHeight
              rows={groupUsers.map((user) => ({ ...user, id: user.id }))} // Ensure DataGrid has an `id` field
              columns={userColumns}
              pageSize={5}
              disableSelectionOnClick
              disableColumnReorder
              className="custom-datagrid"
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "2px solid #ccc",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // Targets the header text directly to make it bold
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#f9f9f9",
                },
                "& .MuiDataGrid-columnSeparator": {
                  display: "none !important", // Hide the column resize handles
                },
              }}
              localeText={{
                noRowsLabel: "No users available",
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default SubgroupUserList;
