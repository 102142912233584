import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./HelpPage.css"; // Create this CSS file for HelpPage specific styles
import HelpMain from "../components/HelpMain";
import HelpPartEditor from "../components/HelpPartEditor";
import HelpSettings from "../components/HelpSettings";
import HelpAccount from "../components/HelpAccount";
import HelpPartsManager from "../components/HelpPartsManager";
import { navigateBack } from "../utils/PageNav";
import strings from "../utils/strings";

import LoadingSpinner from "../components/LoadingSpinner";

function HelpPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the previous path from the state passed via navigation
  const previousPath = location.state?.from;

  // Function to render the correct help component based on the previous route
  const renderHelpContent = () => {
    switch (previousPath) {
      case strings.partsNav:
        return <HelpPartsManager />;
      case strings.partEditorNav:
        return <HelpPartEditor />;
      case strings.settingsNav:
        return <HelpSettings />;
      case strings.accountNav:
        return <HelpAccount />;
      default:
        return <HelpMain />;
    }
  };

  const onBackClick = () => {
    navigateBack(navigate, previousPath);
  };

  return (
    <div id="help-page">
      <div id="helpSection">
        <h3>nCounter Help Page</h3>
        <div className="container">
          {renderHelpContent()}
          <div className="help-button-group">
            <button id="backBtn" onClick={onBackClick}>
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpPage;
