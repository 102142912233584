import { getAllParts, clearAllParts } from "./FirestoreDB";
import { getFunctions, httpsCallable } from "firebase/functions"; // Import firebase functions

// Function to handle file upload (importing CSV data)
export async function importParts(file, group_id, showBanner, subgroups) {
  // console.log("importParts: ", file);

  if (!file) {
    throw new Error("No file provided");
  }

  return new Promise(async (resolve, reject) => {
    try {
      const functions = getFunctions();
      const addOrUpdatePart = httpsCallable(functions, "addOrUpdatePart"); // Cloud function call

      await clearAllParts(group_id);
      console.log("All existing parts cleared.");

      const reader = new FileReader();
      reader.onload = async (e) => {
        const csvData = e.target.result;
        const parts = csvToParts(csvData, subgroups);

        if (parts && parts.length > 0) {
          let addedPartCount = 0;

          // Track added part numbers to avoid duplicate additions
          const addedPartNos = new Set();

          for (const part of parts) {
            if (addedPartNos.has(part.part_no)) {
              console.log(`Skipping duplicate part_no in CSV: ${part.part_no}`);
              continue;
            }

            try {
              const response = await addOrUpdatePart({
                part,
                group_id,
                isUpdate: false, // Assume this is an add operation during CSV import
              });

              if (response.data.success) {
                addedPartCount++;
                addedPartNos.add(part.part_no);
              }
            } catch (error) {
              if (error.message.includes("Duplicate key")) {
                console.warn(
                  `Duplicate key detected for part_no: ${part.part_no}`
                );
              } else {
                console.error("Error adding part via cloud function:", error);
              }
            }
          }

          if (addedPartCount > 0) {
            showBanner(`${addedPartCount} parts added successfully`, "success");
            resolve(true); // Resolve the promise with true
          } else {
            showBanner("No new parts added", "error");
            resolve(false); // Resolve the promise with false
          }
        } else {
          showBanner("Import failed: No valid parts found in CSV.", "error");
          resolve(false); // Resolve the promise with false
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading CSV file:", error);
        reject(false); // Reject the promise with false
      };

      reader.readAsText(file);
    } catch (error) {
      console.error("Error processing file:", error);
      reject(false); // Reject the promise with false
    }
  });
}

// Function to convert CSV to an array of parts
function csvToParts(csv, subgroups) {
  const lines = csv.split("\n");
  const result = [];
  const headers = lines[0].split(",");

  const subgroupMap = subgroups.reduce((map, subgroup) => {
    map[subgroup.id] = subgroup.name;
    return map;
  }, {});

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentLine = lines[i].split(",");

    headers.forEach((header, j) => {
      obj[header.trim()] = currentLine[j]?.trim(); // Use optional chaining
    });

    if (
      obj["part_no"] === undefined ||
      obj["name"] === undefined ||
      obj["piece_weight"] === undefined ||
      obj["units"] === undefined ||
      obj["subgroupId"] === undefined
    ) {
      console.warn(
        `Skipping part with missing required fields: ${JSON.stringify(obj)}`
      );
      continue;
    }
    // Validation
    const partNo = obj["part_no"];
    const name = obj["name"];
    const weightPerPiece = parseFloat(obj["piece_weight"]);
    let units = obj["units"]?.toLowerCase(); // Convert units to lowercase
    const subgroupId = obj["subgroupId"];

    // Validate part_no and name length
    if (partNo.length > 64 || name.length > 64) {
      console.warn(
        `Skipping part with part_no: ${partNo} because part_no or name exceeds 64 characters.`
      );
      continue;
    }

    // Validate piece_weight
    if (isNaN(weightPerPiece) || weightPerPiece > 1_000_000) {
      console.warn(
        `Skipping part with part_no: ${partNo} because piece_weight is invalid or exceeds 1 million.`
      );
      continue;
    }

    // Validate units
    if (!["lb", "kg"].includes(units)) {
      console.warn(
        `Skipping part with part_no: ${partNo} because units are not valid ('lb' or 'kg').`
      );
      continue;
    }

    // Check if subgroupId is valid
    if (subgroupId && subgroupMap[subgroupId]) {
      obj["subgroupId"] = subgroupId;
      obj["subgroupName"] = subgroupMap[subgroupId];
    } else {
      // If subgroupId is invalid or empty, assign empty values
      obj["subgroupId"] = "";
      obj["subgroupName"] = "";
    }

    // Reassign validated and formatted values
    obj["piece_weight"] = weightPerPiece;
    obj["units"] = units;

    // Add the object to the result array if all validations pass
    result.push(obj);
  }

  return result;
}

// Function to trigger the download of the CSV file
function downloadCsvFile(csvStr, filename) {
  const blob = new Blob([csvStr], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// Function to export parts from IndexedDB to CSV
export async function exportParts(group_id, showBanner) {
  try {
    const parts = await getAllParts(group_id);
    let csvStr = "";

    if (parts.length === 0) {
      showBanner("No parts available to export. Exporting an example CSV.", "success");

      // Create example data
      const exampleParts = [
        {
          part_no: "EXAMPLE1",
          name: "Example Part 1",
          piece_weight: 1000,
          units: "lb",
        },
        {
          part_no: "EXAMPLE2",
          name: "Example Part 2",
          piece_weight: 500,
          units: "kg",
        },
      ];

      // Convert example parts to CSV format
      csvStr = partsToCsv(exampleParts);
    } else {
      // Convert actual parts to CSV format
      csvStr = partsToCsv(parts);
    }

    downloadCsvFile(csvStr, "parts.csv");
  } catch (error) {
    console.error("Error exporting parts to CSV:", error);
  }
}

function partsToCsv(partsArray) {
  let csvStr = "";

  // Define the required fields in the correct order
  const keys = [
    "part_no",
    "name",
    "piece_weight",
    "units",
    "subgroupId",
    "subgroupName",
  ];

  // Add header row with the required columns
  csvStr += keys.join(",") + "\n";

  // Loop through the parts and create a CSV string with the required columns
  partsArray.forEach((item) => {
    keys.forEach((key, index) => {
      // Add the value to the CSV string, if undefined, leave it blank
      csvStr += item[key] !== undefined ? item[key] : "";
      if (index < keys.length - 1) csvStr += ","; // Add a comma between fields
    });
    csvStr += "\n"; // Move to the next line after each part
  });

  return csvStr;
}

export function downloadTextAsFile(filename, text) {
  const date = new Date().toLocaleDateString();
  const time = new Date().toLocaleTimeString();

  const element = document.createElement("a");
  const file = new Blob([text], { type: "text/plain" });

  element.href = URL.createObjectURL(file);
  element.download = `${filename}-${date}-${time}.txt`;
  document.body.appendChild(element);
  element.click();

  // Clean up the URL object after the download
  URL.revokeObjectURL(element.href);
  document.body.removeChild(element);
}
