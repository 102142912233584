import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./SettingsPage.css"; // Create this CSS file for HelpPage specific styles

import { useSerial } from "../context/SerialContext.js";

import { downloadTextAsFile } from "../utils/ImportExportFile";
import { navigateToHomePage } from "../utils/PageNav.js";

function SettingsPage() {
  const {
    isConnected,
    onConnectBtn,
    checkSettings,
  } = useSerial();

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigateToHomePage(navigate);
  };

  const onCheckSettings = async () => {
    try {
      await checkSettings();
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  const addOutput = (value) => {
    try {
      const receivedTerminal = document.getElementById("receivedTerminal");
      receivedTerminal.innerText += value + "\n";
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  const onExportBtnClick = () => {
    try {
      const receivedTerminal = document.getElementById("receivedTerminal");
      downloadTextAsFile("settings-log", receivedTerminal.innerText);
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  useEffect(() => {
    if (isConnected) {
      addOutput("Device Connected");
    }
  }, [isConnected]);

  return (
    <div id="settings-page">
      <div className="settings-page-container">
        <h3>Device Settings</h3>
        <div className="container">
          <div className="terminal-container">
            <label>Terminal Output</label>
            <div className="terminal" id="receivedTerminal"></div>
          </div>
          <div className="bottom-container">
            <div className="bottom-button-group">
              <button onClick={onConnectBtn}>
                {isConnected ? "CONNECTED" : "CONNECT"}
              </button>
              <button onClick={onCheckSettings} disabled={!isConnected}>SETTINGS</button>
              <button onClick={onExportBtnClick}>EXPORT</button>
              <button onClick={handleBackClick}>BACK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
