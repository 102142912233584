import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Avatar } from "gestalt";
import "gestalt/dist/gestalt.css";

import logo from "../assets/loadstar-sensors-logo.png";
import "../styles/Header.css";
import "../styles/SideMenu.css";

import strings from "../utils/strings";

import HelpButton from "./HelpButton";

import { UserContext } from "../context/UserContext";
import { navigateToAccountPage, navigateToHomePage } from "../utils/PageNav";

function Header() {
  const navigate = useNavigate();

  const { userData, hasPremiumSubscription } = useContext(UserContext);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const isBasicUser = userData?.role === "user";
  const isLoggedIn = !!userData;

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleAvatarClick = () => {
    navigateToAccountPage(navigate);
  };

  const handleLogoClick = () => {
    navigateToHomePage(navigate);
  };

  // console.log("Header userData: ", userData);

  return (
    <>
      <div className="header">
        <div className="header-content">
          <div className="header-left-section">
            <button className="menu-button" onClick={toggleMenu}>
              <i className="fas fa-bars"></i>
            </button>
            <img
              src={logo}
              height="48"
              width="auto"
              onClick={handleLogoClick}
            />
          </div>

          <h1>nCounter Counting Solution</h1>
          <div className="right-side">
            <div
              className="avatar-container"
              onClick={isLoggedIn ? handleAvatarClick : null}
              title={isLoggedIn ? userData.email : "Guest"}
            >
              <Avatar
                name={isLoggedIn ? userData.email || "User" : "Guest"}
                size="md"
                src={
                  isLoggedIn && userData?.profilePicture
                    ? userData.profilePicture
                    : ""
                }
              />
            </div>
          </div>
        </div>
        <HelpButton />
      </div>
      <div className={`side-menu ${isCollapsed ? "collapsed" : ""}`}>
        <div className="menu-content">
          <ul>
            <li>
              <Link to={strings.homepageNav}>Home</Link>
            </li>
            {hasPremiumSubscription && (
              <li>
                <Link to={strings.partsNav}>
                  {isBasicUser ? "Parts List" : "Parts Manager"}
                </Link>
              </li>
            )}
            {!isBasicUser && hasPremiumSubscription && (
              <li>
                <Link to={strings.partEditorNav}>Add Part</Link>
              </li>
            )}
            <li>
              <Link to={strings.settingsNav}>Settings</Link>
            </li>
            <li>
              <Link to={strings.accountNav}>Account</Link>
            </li>
            <li>
              <Link to={strings.aboutNav}>About</Link>
            </li>
            <li>
              <Link to={strings.helpNav}>Help</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
