import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { navigateToAccountPage } from "../utils/PageNav.js";

import "./SubgroupPage.css";

import SubgroupList from "../components/SubgroupList.js";
import SubgroupUserList from "../components/SubgroupUserList.js";

function SubgroupPage() {
  const navigate = useNavigate();

  const onBackClick = () => {
    navigateToAccountPage(navigate);
  };

  return (
    <div className="subgroup-page">
      <div className="subgroup-page-header-container">
        <h3 id="subgroup-page-title">Subgroup Management</h3>
      </div>
      <div className="subgroup-container">
        <div className="subgroup-page-section">
          <SubgroupList />
          <SubgroupUserList />

          <div className="subgroup-bottom-buttons">
            <button id="backBtn" onClick={onBackClick}>
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubgroupPage;
