import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Select, MenuItem, IconButton } from "@mui/material";
import { getGroupUsers } from "../utils/FirestoreDB.js";
import { capitalizeFirstLetter } from "../utils/stringUtils.js";
import LoadingContentWrapper from "../components/LoadingContentWrapper";
import { navigateToSubgroupPage } from "../utils/PageNav.js";

import { Spinner } from "gestalt";
import "gestalt/dist/gestalt.css";

import "../styles/GroupUsersList.css";

import { UserContext } from "../context/UserContext.js";

function GroupUsersList() {
  const {
    user,
    userData,
    loading,
    refreshUserData,
    scales,
    isAdminOrOwner,
    fetchGroupScales,
    groupCounts,
    updateGroupScales,
    updateGroupDetails,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const functions = getFunctions();
  const [groupUsers, setGroupUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingState, setLoadingState] = useState({}); // Manage loading state for each user
  const groupId = userData.group_id;
  const hasReachedUserLimit = groupCounts?.userCount >= groupCounts?.userMax;

  useEffect(() => {
    if (groupId && isAdminOrOwner) {
      fetchGroupUsers(groupId);
    }
  }, [groupId, isAdminOrOwner]);

  const fetchGroupUsers = async (groupId) => {
    setLoadingUsers(true);
    try {
      const users = await getGroupUsers(groupId);
      setGroupUsers(users);
    } catch (error) {
      console.error("Error fetching group users: ", error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleRoleChange = async (userId, newRole) => {
    setLoadingState((prevState) => ({ ...prevState, [userId]: true }));
    try {
      const updateUserRole = httpsCallable(functions, "updateUserRoleInGroup");
      const result = await updateUserRole({ groupId, userId, newRole });
      console.log(result.data.message);

      // Update the role locally after successful change
      setGroupUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.error("Error updating role:", error);
    } finally {
      setLoadingState((prevState) => ({ ...prevState, [userId]: false }));
    }
  };

  const handleRemoveUser = async (userId) => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      setLoadingState((prevState) => ({ ...prevState, [userId]: true }));
      try {
        const removeUserFunc = httpsCallable(functions, "removeUserFromGroup");
        const result = await removeUserFunc({ groupId, userId });
        console.log(result.data.message);

        // Refresh user list after removal
        setGroupUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== userId)
        );
      } catch (error) {
        console.error("Error removing user: ", error);
      } finally {
        setLoadingState((prevState) => ({ ...prevState, [userId]: false }));
      }
    }
  };

  const onManageSubgroupsClick = () => {
    navigateToSubgroupPage(navigate);
  };

  if (loadingUsers) {
    return (
      <LoadingContentWrapper
        loading={loadingUsers}
        accessibilityLabel="Loading users..."
      ></LoadingContentWrapper>
    );
  }

  const columns = [
    {
      field: "displayName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => params.row.displayName || "Unknown",
    },
    { field: "email", headerName: "Email", flex: 1.5 },
    {
      field: "role",
      headerName: "Role",
      flex: 0.6,
      renderCell: (params) => {
        const groupUser = params.row;
        if (loadingState[groupUser.id]) {
          return <Spinner accessibilityLabel="Loading" show={true} />;
        }
        return groupUser.role === "owner" ? (
          <span>{capitalizeFirstLetter(groupUser.role)}</span>
        ) : (
          <Select
            value={groupUser.role}
            onChange={(e) => handleRoleChange(groupUser.id, e.target.value)}
            disabled={loadingState[groupUser.id]}
            sx={{
              width: "100px", // Consistent width
              height: "35px", // Set height of the select box
              fontSize: "0.875rem", // Match DataGrid font size
              fontWeight: "400", // Match DataGrid font weight
              color: "#333", // Match DataGrid font color
              "& .MuiSelect-select": {
                padding: "10px", // Add padding inside the select box
              },
            }}
          >
            <MenuItem
              value="admin"
              sx={{ fontSize: "0.875rem", fontWeight: "400", color: "#333" }}
            >
              Admin
            </MenuItem>
            <MenuItem
              value="editor"
              sx={{ fontSize: "0.875rem", fontWeight: "400", color: "#333" }}
            >
              Editor
            </MenuItem>
            <MenuItem
              value="user"
              sx={{ fontSize: "0.875rem", fontWeight: "400", color: "#333" }}
            >
              User
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const groupUser = params.row;
        if (groupUser.role !== "owner") {
          return (
            <button
              color="error"
              className="remove-btn"
              title="Remove"
              onClick={() => handleRemoveUser(groupUser.id)}
              disabled={loadingState[groupUser.id]}
            >
              ❌
            </button>
          );
        }
        return null; // No actions for the owner
      },
    },
  ];

  return (
    <div className="group-users-section">
      <h3>Group Users</h3>
      {groupUsers.length === 0 ? (
        <p>No users available</p>
      ) : (
        <>
          {hasReachedUserLimit && (
            <p>You have reached the maximum number of users for your plan.</p>
          )}
          {isAdminOrOwner && (
            <button onClick={onManageSubgroupsClick}>Manage Subgroups</button>
          )}
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              autoHeight
              rows={groupUsers.map((user) => ({ ...user, id: user.id }))} // Ensure DataGrid has an `id` field
              columns={columns}
              pageSize={5}
              disableSelectionOnClick
              disableColumnReorder
              className="custom-datagrid"
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  fontSize: "16px",
                  borderBottom: "2px solid #ccc",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // Targets the header text directly to make it bold
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#f9f9f9",
                },
                "& .MuiDataGrid-columnSeparator": {
                  display: "none !important", // Hide the column resize handles
                },
              }}
              localeText={{
                noRowsLabel: "No users available",
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default GroupUsersList;
