import $ from "jquery";

// Conversion constants
export const lbToKg = 0.453592;
export const kgToLb = 2.20462;

let defaultUnits = "lb";

// Function to convert weight
export function convertWeight(value, fromUnit, toUnit) {
  // console.log("convertWeight: ", value, fromUnit, toUnit);
  if (isNaN(parseFloat(value))) {
    return 0;
  }
  if (fromUnit === toUnit) return value;
  if (fromUnit === "lb" && toUnit === "kg") return value * lbToKg;
  if (fromUnit === "kg" && toUnit === "lb") return value * kgToLb;
  return value;
}

// Function to change units and update the display
export function changeUnits(lb, kg, id, precision = 4) {
  const selectedUnit = $("#units").val();
  const newWeight = Number(selectedUnit === "lb" ? lb : kg);
  document.getElementById(id).textContent = newWeight.toFixed(precision);
}

// Function to set the default units based on the device's response
export function setDefaultUnits(unitResponse) {
  defaultUnits = unitResponse.toLowerCase().includes("kg") ? "kg" : "lb";
  $("#units").val(defaultUnits).trigger("change.select2");

  const defaultUnitsID = document.getElementById("device-default-units-div");
  defaultUnitsID.textContent = `Default: ${defaultUnits}`;
}

// Function to get the current default units
export function getDefaultUnits() {
  return defaultUnits;
}
