import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AboutPage.css"; // Create this CSS file for HelpPage specific styles
import { navigateToHomePage } from "../utils/PageNav";

function AboutPage() {
  const navigate = useNavigate();

  const onBackClick = () => {
    navigateToHomePage(navigate);
  };

  return (
    <div id="about-page">
      <div id="aboutSection">
        <h3>About</h3>
        <div className="container">
          <div>
            <h4>nCounter Counting Solution</h4>

            <p>
              <strong>Version:</strong> 08.2024
            </p>
            <p>
              <strong>Company:</strong> Loadstar Sensors
            </p>

            <p>
              nCounter Counting Solution is a powerful utility tool designed to
              streamline counting operations with precision and ease. Developed
              by Loadstar Sensors, our application is tailored for industries
              requiring accurate and efficient stock management. Whether you're
              counting parts for inventory or performing critical industrial
              processes, nCounter ensures reliable results with every use.
            </p>

            <p>
              For more information or support, please visit{" "}
              <a
                href="https://www.loadstarsensors.com/support/get-support.html"
                target="_blank"
              >
                Loadstar Sensors Support
              </a>
              .
            </p>
          </div>
          <div className="about-button-group">
            <button id="backBtn" onClick={onBackClick}>
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
