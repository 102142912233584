import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box } from "@mui/material";

import "./PartsListPage.css";
import { getAllParts, deletePart } from "../utils/FirestoreDB";
import { importParts, exportParts } from "../utils/ImportExportFile";
import {
  navigateToHomePage,
  navigateToPartEditorPage,
} from "../utils/PageNav.js";
import LoadingSpinnerOverlay from "../components/LoadingSpinnerOverlay.js";
import { capitalizeFirstLetter } from "../utils/stringUtils.js";

import { UserContext } from "../context/UserContext";
import { useBanner } from "../context/BannerContext.js";

function PartsListPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const {
    user,
    userData,
    hasPremiumSubscription,
    loading,
    refreshUserData,
    subgroups,
    isAdminOrOwner,
    userSubgroup,
    parts,
    fetchPartsBySubgroup,
  } = useContext(UserContext);
  const { showBanner } = useBanner();

  // const functions = getFunctions();

  const isBasicUser = userData?.role === "user";

  // useEffect(() => {
  //   // Fetch parts when the component mounts
  //   if (userData?.group_id) {
  //     fetchPartsBySubgroup(userData.group_id, userSubgroup.subgroupId, isAdminOrOwner);
  //   }
  // }, [userData, userSubgroup, isAdminOrOwner]);

  const handleImportClick = () => {
    const userConfirmed = window.confirm(
      "Warning: Importing new parts will clear all existing parts. Please ensure you have backed up your current data using the export function. Do you want to proceed?"
    );

    if (userConfirmed) {
      const fileInput = fileInputRef.current;
      fileInput.click();
    } else {
      console.log("Import action canceled by the user.");
    }
  };

  async function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true); // Show spinner
      try {
        let importPartsFinished = await importParts(
          file,
          userData.group_id,
          showBanner,
          subgroups
        );
        if (importPartsFinished) {
          console.log("Parts imported successfully...updating parts");
        } else {
          console.log("Parts import failed");
        }
      } catch (error) {
        console.error("Error during import:", error);
      } finally {
        fetchPartsBySubgroup();
        setIsLoading(false); // Hide spinner after import finishes
      }
    } else {
      console.error("No file selected");
    }
  }

  const handleExportClick = () => {
    exportParts(userData.group_id, showBanner);
  };

  const handleNewClick = () => {
    navigateToPartEditorPage(navigate);
  };

  const handleBackClick = () => {
    navigateToHomePage(navigate);
  };

  const handleEditClick = (part) => {
    navigateToPartEditorPage(navigate, part);
  };

  const handleDeleteClick = async (partId) => {
    if (!partId) {
      console.error("Invalid part ID:", partId);
      return;
    }

    try {
      await deletePart(partId, userData.group_id);
      fetchPartsBySubgroup();
    } catch (error) {
      console.error("Failed to delete part:", error);
    }
  };

  const columns = [
    { field: "part_no", headerName: "Part No.", flex: 1 },
    { field: "name", headerName: "Part Name", flex: 1.5 },
    {
      field: "piece_weight",
      headerName: "Wt. Per Piece",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "units",
      headerName: "Units",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    ...(isAdminOrOwner
      ? [
          {
            field: "subgroupName",
            headerName: "Subgroup",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => params.row.subgroupName || "N/A",
          },
        ]
      : []), // Add subgroup column for owners and admins only
    ...(!isBasicUser
      ? [
          {
            field: "actions",
            headerName: "Actions",
            flex: 0.7,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
              <div className="action-buttons">
                <button
                  className="edit-btn"
                  onClick={() => handleEditClick(params.row)}
                >
                  <i className="fas fa-pencil-alt"></i>
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteClick(params.row.id)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            ),
            hide: isBasicUser || !hasPremiumSubscription, // Hide for basic users or if no premium subscription
          },
        ]
      : []), // Add subgroup column for owners and admins only
  ];

  return (
    <div id="parts-list-page">
      <div id="partsListSection">
        <h3>{isBasicUser ? "Parts List" : "Parts Manager"}</h3>
        <div className="container">
          {isAdminOrOwner ? (
            <p>
              {capitalizeFirstLetter(userData?.role)} role has access to all
              parts.
            </p>
          ) : (
            <p>
              <strong>Subgroup: </strong>
              {userSubgroup?.subgroupName
                ? userSubgroup?.subgroupName
                : "You have not been assigned a Subgroup."}
            </p>
          )}
          <div style={{ minHeight: 400, width: "100%" }}>
            <DataGrid
              autoHeight
              rows={parts.map((part) => ({ ...part, id: part.id }))} // Ensure DataGrid has an `id` field
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className="custom-datagrid"
              disableColumnReorder
              localeText={{
                noRowsLabel:
                  "No parts available. Please add parts to the list.", // Custom message
              }}
              sx={{
                width: '750px',
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold", // This makes the headerName bold
                  fontSize: "16px",
                  borderBottom: "2px solid #ccc",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // This targets the header text directly
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#f9f9f9",
                },
                "& .action-buttons button": {
                  marginRight: "10px",
                  color: "#007bff",
                },
                "& .edit-btn": {
                  color: "white",
                },
                "& .delete-btn": {
                  color: "white",
                },
                "& .MuiDataGrid-columnSeparator": {
                  display: "none !important", // Hide the column resize handles
                },
              }}
            />
          </div>

          <div className="bottom-container">
            <div className="bottom-button-group">
              <input
                type="file"
                ref={fileInputRef}
                id="fileInput"
                style={{ display: "none" }}
                accept=".csv"
                onChange={handleFileChange}
              />
              {!isBasicUser && hasPremiumSubscription && (
                <button onClick={handleImportClick}>IMPORT</button>
              )}
              <button onClick={handleExportClick}>EXPORT</button>
              {!isBasicUser && hasPremiumSubscription && (
                <button onClick={handleNewClick}>ADD PART</button>
              )}
              <button onClick={handleBackClick}>BACK</button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinnerOverlay />}
    </div>
  );
}

export default PartsListPage;
